<template>
  <div class="d-inline">
    <v-tooltip bottom class="d-inline">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          :disabled="!permissions.update && !permissionUpdate"
          v-on="on"
          color="primary"
          @click="$emit('update')"
          ><slot name="icon">mdi-pencil</slot></v-icon
        >
      </template>
      <span><slot name="tooltip">Edit</slot></span>
    </v-tooltip>
    <v-tooltip bottom class="d-inline">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          :disabled="!permissions.delete && !permissionDelete"
          v-on="on"
          color="error"
          @click="$emit('delete')"
          ><slot name="icon">mdi-trash-can</slot></v-icon
        >
      </template>
      <span><slot name="tooltip">Delete</slot></span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    permissionUpdate: {
      type: Boolean,
      default: false
    },
    permissionDelete: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['permissions'])
  }
};
</script>

<style>
</style>
