import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline"},[_c(VTooltip,{staticClass:"d-inline",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"disabled":!_vm.permissions.update && !_vm.permissionUpdate,"color":"primary"},on:{"click":function($event){return _vm.$emit('update')}}},'v-icon',attrs,false),on),[_vm._t("icon",[_vm._v("mdi-pencil")])],2)]}}],null,true)},[_c('span',[_vm._t("tooltip",[_vm._v("Edit")])],2)]),_c(VTooltip,{staticClass:"d-inline",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"disabled":!_vm.permissions.delete && !_vm.permissionDelete,"color":"error"},on:{"click":function($event){return _vm.$emit('delete')}}},'v-icon',attrs,false),on),[_vm._t("icon",[_vm._v("mdi-trash-can")])],2)]}}],null,true)},[_c('span',[_vm._t("tooltip",[_vm._v("Delete")])],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }